import React, { useState, useRef } from 'react';
import ValidPassword from '../components/auth/ValidPassword';
import { useNavigate } from 'react-router-dom';
import '../utils/css/views/forgetPassword.css';
import { useGlobalState } from '../views/GlobalStateContext';

function ForgetPassword() {

    const { urlAPI } = useGlobalState();

    const [step, setStep] = useState(1);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [boolPass, setBoolPass] = useState('');
    const [email, setEmail] = useState('');
    const [inputs, setInputs] = useState(Array(6).fill(''));
    const [secretCode, setSecretCode] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    

    const inputRefs = useRef(inputs.map(() => React.createRef()));

    const navigate = useNavigate();

    const handleClick = () => {
        if (step === 3) {

        } else {
            if (step === 1) {
                const forgetPasswordData = {
                    email: email
                };
                fetch(urlAPI + "/auth/forgetPassword", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(forgetPasswordData),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.message == "Envoie du mail réussi.") {
                        setStep(step + 1)
                    } else {
                        setErrorMessage(data.message)
                        setStep(step + 1)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }
        }
    }

    const handleChange = (index, value) => {
        if (value === '' || (value.length === 1 && !isNaN(value))) {
            const newInputs = inputs.map((val, i) => (i === index ? value : val));
            setInputs(newInputs);

            const total = newInputs.join('');

            if (index < inputs.length - 1) {
                inputRefs.current[index + 1].current.focus();
            }

            if (total.length === 6) {
                const validCodeData = {
                    email: email,
                    userCode: total
                };
                fetch(urlAPI + "/auth/validCodeForgetPassword", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(validCodeData),
                })
                .then(response => response.json())
                .then(data => {
                    if (data.message == "Code correct") {
                        setSecretCode(true)
                        setTimeout(() => {
                            setStep(step + 1);
                        }, 3000);
                    } else {
                        setSecretCode(false)
                        setErrorMessage(data.message)
                        setTimeout(() => {
                            setInputs(Array(6).fill(''));
                        }, 3000);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }
        }
    };

    const validatePassword = () => {
        const updateData = {
            newPassword: newPassword,
            confirmPassword: confirmPassword,
            email: email
        };
        fetch(urlAPI + "/auth/newPassword", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updateData),
        })
        .then(response => response.json())
        .then(data => {
            if (data.message == "Mot de passe mit à jour avec succès") {
                setSecretCode(true)
                navigate('/');
            } else {
                setErrorMessage(data.message)
            }
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    
    }

    return (
        <div className=''>
            {step === 1 && (
                <div className='container-step-password'>
                    <h1 className='title-step-password'>Mot de passe oublié ?</h1>
                    <p className='text-step-password'>Entrez votre adresse e-mail renseigné lors de l'inscription, <br />
                        nous vous enverrons un code par mail.</p>
                    <div className='container-content-step-password'>
                        <div className='container-input-step-password'>
                            <label>Adresse e-mail</label>
                            <input
                                type="email"
                                className="input-step-password"
                                id="password"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <button onClick={handleClick} className="submit-btn-password" type="submit">Envoyer</button>
                    </div>
                </div>
            )}
            {step === 2 && (
                <div className='container-step-password'>
                    <h1 className='title-step-password'>Mot de passe oublié ?</h1>
                    <p className='text-step-password'>Entrez votre code à 6 chiffres</p>
                    <div>
                        {inputs.map((value, index) => (
                            <input
                                className='input-code-step-password'
                                key={index}
                                ref={inputRefs.current[index]}
                                maxLength={1}
                                value={value}
                                onChange={(e) => handleChange(index, e.target.value)}
                            />
                        ))}
                        {secretCode === false && (<p className='wrong-code'>Code inccorect</p>)}
                        {secretCode === true && (<p className='right-code'>Code correct</p>)}
                    </div>
                    <div className='container-content-text-password'>
                        <p>Vous n’avez pas reçu d’e-mail ?</p>
                        <p className='underline'>Envoyer l'e-mail</p>
                    </div>
                </div>
            )}
            {step === 3 && (
                <div className='container-step-password'>
                    <h1 className='title-step-password'>Mot de passe oublié ?</h1>
                    <p className='text-step-password'>Réinitialisez votre mot de passe</p>
                    <div className='container-input-step-password'>
                        <label>Nouveau mot de passe</label>
                        <input
                            type="password"
                            className="input-step-password"
                            id="password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <ValidPassword password={newPassword} style={{ width: '300px' }} />
                    <div className='container-input-step-password top'>
                        <label>Confirmez le mot de passe</label>
                        <input
                            type="password"
                            className="input-step-password"
                            id="password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button onClick={validatePassword} className="submit-btn-password top" type="submit">Confirmer</button>
                    {errorMessage && <p className="error-message-connexion">{errorMessage}</p>}
                </div>
            )}
        </div>
    )
}

export default ForgetPassword;
import React from 'react';
import '../../utils/css/components/challenges/boxChallenge.css'
import StatusChallenge from '../../components/challenges/StatusChallenge'
import AddSell from '../challenges/AddSell';
import RequiredElement from '../../components/challenges/RequiredElement'

const BoxChallenge = (props) => {

  return (
    <div>
      <div className='container-challenge-name'>
        <h2 className='subtitle-challenges'>{props.title}</h2>
        <p className='text-name-challenge'>{props.name}</p>
      </div>
      <div className='container-bloc-challenge-trimestre'>
        <div className='container-challenge-value first'>
          <label className='text-value-challenge'>Date</label>
          <p className='text-value-challenge'>
            du <span className='bold'>{props.dateStart}</span> au <span className='bold'>{props.dateEnd}</span>
          </p>
        </div>
        <div className='container-challenge-value'>
          <label className='text-value-challenge'>
            Gain
          </label>
          <p className='text-value-challenge bold'>{props.gain}€</p>
        </div>
        <div className='container-challenge-value'>
          <label className='text-value-challenge'>Statut</label>
          <StatusChallenge status={props.status} document={props.document}/>
        </div>
        <div className='container-challenge-value'>
          <label className='text-value-challenge'>
            Date limite de dépôt
          </label>
          <p className='text-value-challenge bold'>{props.dateLimit}</p>
        </div>
        <div className='container-challenge-value'>
          {props.document === false && <RequiredElement />}
        </div>
      </div>
      <div className='container-button-moreinfo-sell'>
        <AddSell 
          type={props.type} 
          name={props.name} 
          limit_date={props.dateLimit}
          end_date={props.dateEnd}
          pathMore={props.urlMoreRedirect} 
          pathSell={props.urlSellRedirect} 
          id={props.id}
          document={props.document}
          status={props.status}
        />
      </div>
    </div>
  );
};

export default BoxChallenge;

// Importez useState pour définir timer
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/general/Sidebar';
import Header from '../components/general/Header';
import Timer from '../components/general/Timer';
import '../utils/css/views/detailsChallengeTrimestriel.css';
import addIcon from '../assets/icon/add_circle.png'
import HistoriqueVente from '../components/challenges/HistoriqueVente';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../views/GlobalStateContext';
import { useLocation } from 'react-router-dom';
import Loader from '../components/general/LoaderGIF';
import FileHandler from '../components/challenges/globalDoc';
import warningIcon from '../assets/icon/warning.png'

function DetailsChallengeTrimestriel() {
    const { urlAPI } = useGlobalState();
    const userToken = localStorage.getItem('userToken');
    let navigate = useNavigate();
    const { id } = useParams();
    const [challengeData, setChallengeData] = useState(null);
    const [ventesData, setVentesData] = useState(null);
    const [timer, setTimer] = useState('');
    const location = useLocation();
    const [fileName, setFileName] = useState('');
    const { name, limitDate, type, id_chall, dateEnd } = location.state || {};
    const [isLoading, setIsLoading] = useState(true);

    const currentDate = new Date();
    const endDate = new Date(dateEnd);
    const isDatePassed = currentDate > endDate;

    const { emailPN, telPN } = useGlobalState();

    useEffect(() => {
        fetchDetailsChallenge();
        fetchDetailsSells();
    }, []);

    const fetchDetailsSells = async () => {
        const url = `${urlAPI}/challenge/getSellsByChallenge`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                },
                body: JSON.stringify({ id_challenge: id }),
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données de l\'utilisateur');
            }
            const data = await response.json();
            
            setVentesData(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails de l’utilisateur:', error);
        }
    }

    const fetchDetailsChallenge = async () => {
        const url = `${urlAPI}/challenge/getChallengeByID/${id}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${userToken}`,
                }
            });
            if (!response.ok) {
                throw new Error('Problème lors de la récupération des données du challenge');
            }
            const data = await response.json();
            setChallengeData(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des détails du challenge: ', error);
        }
    }

    const handleClicDetails = (item) => {
        if (item[1] == 0) {
            navigate(`/challenges/challenge_trimestriel_details/ventes/${item[0]}`, {
                state: {
                    name: name,
                    limitDate: limitDate,
                    type: type,
                    id: id_chall
                }
            });
        } else if (item[1] == 1) {
            navigate(`/challenges/challenge_exceptionnel_details/ventes/${item[0]}`, {
                state: {
                    name: name,
                    limitDate: limitDate,
                    type: type,
                    id: id_chall
                }
            });
        }
    };
    const handleFileAdded = (file) => {
        setFileName(file.name);
    };

    return (
        <div className='container-challenges'>
            <Sidebar currentPage={"Challenges"} />
            <div className='container-all-data-challenges'>
                <Header title={name} back={true} />
                <div className='container-without-header-details-challenge'>
                    {isLoading === true ? (
                        <Loader />
                    ) : (
                        <>
                            <Timer timer={timer} setTimer={setTimer} challengeData={challengeData.end_date} variante={0} />
                            <div className='bandeau-ventes-container'>
                                <h1 className='bandeau-ventes-title'>Mes ventes</h1>
                                <div className='container-add-file'>
                                    {timer && (
                                        <div className='button-ventes-add' onClick={() => handleClicDetails([challengeData.id, challengeData.type_challenge])} >
                                            <img src={addIcon} alt="Add icon" />
                                            <p>J'ajoute mes ventes</p>
                                        </div>
                                    )}
                                </div>
                               
                            </div>
                            <div className='container-vente-info'>
                                <div className='container-vente-info-warning'>
                                <img src={warningIcon} alt="icon warning" className="iconVentes" />
                                </div>
                                    <p>
                                   <span>Pour les challenges trimestriels, vous devez soumettre un seul document regroupant toutes vos ventes en guise de preuve de vente.</span>
                                    <br></br>Vous pourrez toujours modifier ce document ultérieurement sur la page du challenge.</p>
                                    <p></p>
                            </div>
                        </>
                    )}
                    {isLoading === true ? (
                        <Loader />
                    ) : (
                        ventesData && <HistoriqueVente ventes={ventesData} type_challenge={challengeData.type_challenge} timer={timer} challenge={challengeData} />
                    )}
                    <div className='container-text-help-ventes'>
                        <p>
                            Vous avez oublié quelque chose ? <br />
                            <span className='text-help-ventes-bold'>Contactez nous au {telPN}</span> ou sur <br />
                            <span className='text-help-ventes-bold'>{emailPN}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailsChallengeTrimestriel;
